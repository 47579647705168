<!-- @format -->

<template>
  <div class="company-footer">
    <div class="company-footer__links" :class="$mq">
      <a href="https://port-prince.de/" target="_blank">Port Au Prince Home</a>
      <a href="https://port-prince.de/impressum/" target="_blank"> IMPRESSUM</a>
      <a href="https://port-prince.de/datenschutzerklaerung/" target="_blank">
        DATENSCHUTZ
      </a>
    </div>
    <div class="company-footer__copyright">
      &copy; 2020 Port-au-Prince. Alle Rechte vorbehalten. made by
      <a href="http://www.novagraphix.de" target="_blank">novagraphix</a>
    </div>
  </div>
</template>

<script>
  export default {}
</script>
<style lang="scss">
  .company-footer {
    $self: &;
    &__links {
      margin-top: 31px;
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
      font-weight: 700;
      font-size: 1rem;
      justify-content: center;
      text-transform: uppercase;
      a {
        color: $primary;
        &:hover {
          color: white;
        }
        &:not(:last-child):after {
          color: $primary;
          content: '|';
          padding: 0 5px 0 5px;
        }
      }
      &.sm {
        a {
          &:after {
            content: '';
          }
          &:last-child {
            margin-left: 0;
          }
        }
      }
      &.md,
      &.lg,
      &.xl,
      &.xxl {
        flex-direction: row;
      }
    }
    &__copyright {
      margin-top: 13px;
      color: white;
      font-family: 'Roboto';
      font-size: 10px;
      a {
        color: white;
        &:hover {
          color: $secondary;
        }
      }
    }
  }
</style>
